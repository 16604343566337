import '@/Pages/Main/scss/index.scss';
import React, {lazy, Suspense} from 'react';
import withStore from "@/hocs/withStore";
import BaseLayout from "@/Layouts/BaseLayout";
import LazyLoad from 'react-lazyload';
import MainSlider from "@/Pages/Main/Slider";
import CarouselHit from "@/Pages/Main/CarouselHit";
import CarouselBestPrice from "@/Pages/Main/CarouselBestPrice";
// import PrizeWheel from "@/Components/PrizeWheel";

const Collection = lazy(() => import('@/Pages/Main/Collection'));
const Categories = lazy(() => import('@/Pages/Main/Categories'));
const Articles = lazy(() => import('@/Pages/Main/Articles'));

const Main = ({...props}) => {
    return <BaseLayout {...props}>
        <section className={`section`}>
            <MainSlider {...props} />
            <LazyLoad throttle={200} offset={100}>
                <CarouselHit {...props} />
            </LazyLoad>
            <LazyLoad throttle={200} offset={100}>
                <CarouselBestPrice {...props} />
            </LazyLoad>
            <LazyLoad throttle={200} offset={100}>
                <Suspense><Collection {...props} /></Suspense>
            </LazyLoad>
            <LazyLoad throttle={200} offset={100}>
                <Suspense><Categories {...props} /></Suspense>
            </LazyLoad>
            <LazyLoad throttle={200} offset={100}>
                <Suspense><Articles {...props} /></Suspense>
            </LazyLoad>
            {/*<PrizeWheel*/}
            {/*    prizes={[*/}
            {/*        { text: "Подарок № 1", colorBG: "#006856", color: "#ffffff", magnet: false },*/}
            {/*        { text: "Подарок № 2", colorBG: "#24564A", color: "#ffffff", magnet: false },*/}
            {/*        { text: "Подарок № 3", colorBG: "#006856", color: "#ffffff", magnet: false },*/}
            {/*        { text: "Подарок № 4", colorBG: "#24564A", color: "#ffffff", magnet: false },*/}
            {/*        { text: "Подарок № 5", colorBG: "#006856", color: "#ffffff", magnet: true },*/}
            {/*        { text: "Подарок № 6", colorBG: "#24564A", color: "#ffffff", magnet: false }*/}
            {/*    ]}*/}
            {/*/>*/}
        </section>
    </BaseLayout>
};

export default withStore(Main);
